<template>
  <div class="step-lesson-layout" :class="{ 'disable-max-width': disableMaxWidth }">
    <div class="step-lesson-layout-wrapper">
      <div class="step-lesson-layout-head">
        <slot name="head">
          <h1 class="step-lesson-layout-title" v-html="title"></h1>
          <p class="step-lesson-layout-title-annotation" v-html="titleAnnotation"></p>
        </slot>
      </div>
      <div class="step-lesson-layout-content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepLessonLayout',
  props: {
    title: {
      type: String,
      required: false
    },
    titleAnnotation: {
      type: String,
      required: false
    },
    disableMaxWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.step-lesson-layout {
  .step-lesson-layout-wrapper {
    max-width: $training-max-width-tablet;
    margin: 0 auto;
    display: grid;
    gap: $space-sm;
    padding: $space-l 0 $space-xl 0;
  }
  .step-lesson-layout-head {
    padding: 0 $space-ml;
    .step-lesson-layout-title {
      color: $c-secondary;
    }
    .step-lesson-layout-title-annotation {
      color: $c-text;
      font-family: $ff;
      font-weight : $fw-m;
      line-height : 120%;
    }
  }
  .step-lesson-layout-content {
    margin-top: $space-m;
  }
}

@media (min-width: $bp-tablet) {
  .step-lesson-layout {
    .step-lesson-layout-wrapper {
      max-width: $training-max-width;
    }
    &.disable-max-width {
      .step-lesson-layout-wrapper {
        max-width: $bp-desktop;
      }
    }
  }
}
</style>

<style lang="scss">
.step-lesson-layout {
  .first-block {
    margin-top: -$space-m !important;
  }
  .step-lesson-layout-content {
    .training-secondary-block + .training-secondary-block {
      margin-top: $space-xs;
    }
    .training-question-block {
      margin: $space-s auto;
      max-width: 400px;
    }
    .buttons-wrapper {
      margin-top: $space-l;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 $space-m;
      > .app-button-layout {
        width: 100%;
      }
    }
  }
  .paragraphe {
    color: $c-text-dark;
    font-family: $ff-text;
    font-weight: $fw-m;
    line-height: $ln-m;
    .sub-title {
      font-family: $ff-big;
      font-size: $fz-xl;
      font-weight: $fw-l;
    }
  }
  .paragraphe-alt {
    color: $c-text;
    font-family: $ff-text;
    font-size: $fz-sm;
    font-weight: $fw-m;
    line-height: $ln-m;
    .sub-title {
      font-family: $ff-big;
      font-size: $fz-xl;
      font-weight: $fw-l;
    }
  }
  .app-text-block {
    margin: $space-l 0;
  }
  .separator {
    padding-bottom: $space-m;
    border-top: 1px solid $c-border;
  }
  .app-decoration-block.end-block {
    margin: $space-l auto 0 auto;
  }
}

@media (min-width: $bp-tablet) {
  .step-lesson-layout {
    .step-lesson-layout-content {
      padding: 0 $space-s;
      .buttons-wrapper {
        > .app-button-layout {
          width: 360px;
        }
      }
    }
  }
}
</style>

<template>
  <step-lesson-layout :title="$t('module2.part3.lesson2.title')" :title-annotation="$t('module2.part3.lesson2.intro')">
    <template v-slot:content>
      <!-- BLOCK1 -->
      <div class="app-text-block first-block">
        <div class="paragraphe-alt">
          <p class="label">{{ $t('module2.part3.lesson2.stepLabel')}} 1</p>
          <p class="sub-title">{{ $t('module2.part3.lesson2.block1.subTitle') }}</p>
        </div>
        <div class="documents-tabs-wrapper image-tabs-wrapper">
          <app-tabs :tabs="block1Tabs" :disable-default-tab="true">
            <template v-slot:tab="{ name, image, isCurrent }">
              <div class="document-tab image-tab" :class="{ current: isCurrent }">
                <img :src="image" :alt="name">
              </div>
            </template>
            <template v-slot:content="{ name }">
              <div class="document-tab-content image-tab-content" v-if="name === 'contrat'">
                <p class="title">{{ $t('module2.part3.lesson2.block1.tab1.title') }}</p>
                <p class="head" v-html="$t('module2.part3.lesson2.block1.tab1.head')"></p>
                <div class="training-warning">
                  <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
                  <p class="paragraphe">{{ $t('module2.part3.lesson2.block1.tab1.warning') }}</p>
                </div>
                <div class="app-text-drawer">
                  <drawer>
                    <template v-slot:head="{ isOpen }">
                      <div class="app-text-drawer-head" :class="{ open: isOpen }">
                        {{ $t('module2.part3.lesson2.block1.tab1.drawerHead') }}
                      </div>
                    </template>
                    <template v-slot:content>
                      <div class="app-text-drawer-content">
                        <p v-html="$t('module2.part3.lesson2.block1.tab1.drawerContent')"></p>
                      </div>
                    </template>
                  </drawer>
                </div>
              </div>
              <div class="document-tab-content image-tab-content" v-else-if="name === 'rge'">
                <p class="title">{{ $t('module2.part3.lesson2.block1.tab2.title') }}</p>
                <p class="head">{{ $t('module2.part3.lesson2.block1.tab2.head') }}</p>
                <div class="training-warning">
                  <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
                  <p class="paragraphe" v-html="$t('module2.part3.lesson2.block1.tab2.warning')"></p>
                </div>
              </div>
            </template>
          </app-tabs>
        </div>
      </div>

      <!-- BLOCK 2 -->
      <div class="app-text-block">
        <div class="paragraphe-alt">
          <p class="label">{{ $t('module2.part3.lesson2.stepLabel')}} 2</p>
          <p class="sub-title">{{ $t('module2.part3.lesson2.block2.subTitle') }}</p>
        </div>
        <div class="documents-tabs-wrapper image-tabs-wrapper">
          <app-tabs :tabs="block2Tabs" :disable-default-tab="true">
            <template v-slot:tab="{ name, image, isCurrent }">
              <div class="document-tab image-tab" :class="{ current: isCurrent }">
                <img :src="image" :alt="name">
              </div>
            </template>
            <template v-slot:content="{ name }">
              <div class="document-tab-content image-tab-content" v-if="name === 'cadre'">
                <p class="title">{{ $t('module2.part3.lesson2.block2.tab1.title') }}</p>
                <p class="head">{{ $t('module2.part3.lesson2.block2.tab1.head') }}</p>
                <div class="training-warning">
                  <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
                  <p class="paragraphe" v-html="$t('module2.part3.lesson2.block2.tab1.warning')"></p>
                </div>
                <div class="app-text-drawer">
                  <drawer>
                    <template v-slot:head="{ isOpen }">
                      <div class="app-text-drawer-head" :class="{ open: isOpen }">
                        {{ $t('module2.part3.lesson2.block2.tab1.drawerHead') }}
                      </div>
                    </template>
                    <template v-slot:content>
                      <div class="app-text-drawer-content">
                        <p v-html="$t('module2.part3.lesson2.block2.tab1.drawerContent')"></p>
                      </div>
                    </template>
                  </drawer>
                </div>
              </div>
              <div class="document-tab-content image-tab-content" v-if="name === 'devis'">
                <p class="title">{{ $t('module2.part3.lesson2.block2.tab2.title') }}</p>
                <p class="head">{{ $t('module2.part3.lesson2.block2.tab2.head') }}</p>
                <div class="training-warning">
                  <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
                  <p class="paragraphe" v-html="$t('module2.part3.lesson2.block2.tab2.warning')"></p>
                </div>
                <div class="app-text-drawer">
                  <drawer>
                    <template v-slot:head="{ isOpen }">
                      <div class="app-text-drawer-head" :class="{ open: isOpen }">
                        {{ $t('module2.part3.lesson2.block2.tab2.drawerHead') }}
                      </div>
                    </template>
                    <template v-slot:content>
                      <div class="app-text-drawer-content">
                        <p v-html="$t('module2.part3.lesson2.block2.tab2.drawerContent')"></p>
                      </div>
                    </template>
                  </drawer>
                </div>
              </div>
            </template>
          </app-tabs>
        </div>
      </div>

      <!-- BLOCK 3 -->
      <div class="app-text-block">
        <div class="paragraphe-alt">
          <p class="label">{{ $t('module2.part3.lesson2.stepLabel')}} 3</p>
          <p class="sub-title">{{ $t('module2.part3.lesson2.block3.subTitle1') }}</p>
        </div>
        <div class="documents-tabs-wrapper image-tabs-wrapper">
          <app-tabs :tabs="block3Tabs" :disable-default-tab="true">
            <template v-slot:tab="{ name, image, isCurrent }">
              <div class="document-tab image-tab" :class="{ current: isCurrent }">
                <img :src="image" :alt="name">
              </div>
            </template>
            <template v-slot:content="{ name }">
              <div class="document-tab-content image-tab-content" v-if="name === 'facture'">
                <p class="title">{{ $t('module2.part3.lesson2.block3.tab1.title') }}</p>
                <p class="head">{{ $t('module2.part3.lesson2.block3.tab1.head') }}</p>
                <div class="training-warning">
                  <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
                  <p class="paragraphe" v-html="$t('module2.part3.lesson2.block3.tab1.warning')"></p>
                </div>
                <div class="app-text-drawer">
                  <drawer>
                    <template v-slot:head="{ isOpen }">
                      <div class="app-text-drawer-head" :class="{ open: isOpen }">
                        {{ $t('module2.part3.lesson2.block3.tab1.drawerHead') }}
                      </div>
                    </template>
                    <template v-slot:content>
                      <div class="app-text-drawer-content">
                        <p v-html="$t('module2.part3.lesson2.block3.tab1.drawerContent')"></p>
                      </div>
                    </template>
                  </drawer>
                </div>
              </div>
              <div class="document-tab-content image-tab-content" v-if="name === 'ah'">
                <p class="title">{{ $t('module2.part3.lesson2.block3.tab2.title') }}</p>
                <p class="head">{{ $t('module2.part3.lesson2.block3.tab2.head') }}</p>
                <div class="training-warning">
                  <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
                  <p class="paragraphe" v-html="$t('module2.part3.lesson2.block3.tab2.warning')"></p>
                </div>
                <div class="app-text-drawer">
                  <drawer>
                    <template v-slot:head="{ isOpen }">
                      <div class="app-text-drawer-head" :class="{ open: isOpen }">
                        {{ $t('module2.part3.lesson2.block3.tab2.drawerHead') }}
                      </div>
                    </template>
                    <template v-slot:content>
                      <div class="app-text-drawer-content">
                        <p v-html="$t('module2.part3.lesson2.block3.tab2.drawerContent')"></p>
                      </div>
                    </template>
                  </drawer>
                </div>
              </div>
            </template>
          </app-tabs>
        </div>
        <div class="paragraphe-alt">
          <p class="sub-title">{{ $t('module2.part3.lesson2.block3.subTitle2') }}</p>
          <p v-html="$t('module2.part3.lesson2.block3.content2')"></p>
        </div>
      </div>

      <!-- BLOCK 4 -->
      <div class="app-text-block block4">
        <p class="title">{{ $t('module2.part3.lesson2.block4.title') }}</p>
        <p class="paragraphe" v-html="$t('module2.part3.lesson2.block4.content')"></p>
      </div>

      <div class="buttons-wrapper">
        <app-button-layout @click="$emit('next-tab')">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import AppTabs from '@/components/AppTabs'
import Drawer from '@/components/Drawer'

export default {
  name: 'Module2Part3Lesson2',
  components: { StepLessonLayout, AppButtonLayout, AppTabs, Drawer },
  emits: ['next-tab'],
  data () {
    return {
      block1Tabs: [
        {
          name: 'rge',
          image: require('@/assets/module2/part3/doc-rge.svg')
        },
        {
          name: 'contrat',
          image: require('@/assets/module2/part3/doc-contrat.svg')
        }
      ],
      block2Tabs: [
        {
          name: 'cadre',
          image: require('@/assets/module2/part3/doc-cadre.svg')
        },
        {
          name: 'devis',
          image: require('@/assets/module2/part3/doc-devis.svg')
        }
      ],
      block3Tabs: [
        {
          name: 'facture',
          image: require('@/assets/module2/part3/doc-facture.svg')
        },
        {
          name: 'ah',
          image: require('@/assets/module2/part3/doc-ah.svg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.app-text-block {
  .label {
    margin-bottom: $space-xs;
    width: fit-content;
    padding: $space-xs $space-s;
    font-size: $fz-s;
    font-weight: $fw-l;
    border-radius: 50px ;
    color: white;
    background-color: $c-secondary;
  }
  .paragraphe-alt {
    padding: 0 $space-m;
    .sub-title {
      margin-bottom: $space-s;
    }
  }
  .documents-tabs-wrapper + .paragraphe-alt {
    margin-top: $space-l;
  }
}
.block4 {
  padding: 0 $space-m;
}
.documents-tabs-wrapper {
  margin-top: $space-s;
  .document-tab {
    height: 160px;
  }
  .document-tab-content {
    padding: $space-m $space-sm;
    .title {
      font-size: $fz-xl;
      margin-bottom: $space-xs;
    }
    .head {
      color: $c-text-dark;
      font-weight: $fw-m;
    }
    .training-warning {
      margin-top: $space-m;
      .paragraphe {
        color: $c-text;
      }
    }
    .app-text-drawer {
      margin-top: $space-m;
    }
  }
}
.document-button {
  max-width: 360px;
  margin-top: $space-m;
  a {
    text-decoration: none;
  }
  .app-button-layout {
    width: 100%;
  }
}
</style>

<style lang="scss">
.documents-tabs-wrapper {
  .app-tabs {
    .tabs-wrapper .tabs {
      display: grid;
      gap: 2px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>

<template>
  <div class="drawer" :class="{ open: drawerOpen }">
    <div class="drawer-head" @click="toggleDrawer" v-if="showHead">
      <slot name="head" v-bind:isOpen="drawerOpen"></slot>
    </div>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="$emit('drawer-closed')"
    >
      <div v-show="drawerOpen">
        <div class="drawer-content">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'drawer',
  props: {
    isInitialOpen: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    showHead: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['drawer-closed', 'drawer-opened', 'toggled'],
  data () {
    return {
      drawerOpen: this.isInitialOpen
    }
  },
  methods: {
    toggleDrawer () {
      this.drawerOpen = !this.drawerOpen
      this.$emit('toggled', this.drawerOpen)
    },
    enter (element) {
      element.style.width = getComputedStyle(element).width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = 'auto'
      element.style.position = 'static'
      element.style.visibility = 'visible'
      element.style.height = 0

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line
      getComputedStyle(element).height

      setTimeout(() => {
        element.style.height = height
      })
    },
    afterEnter (element) {
      this.$emit('drawer-opened')
      element.style.height = 'auto'
    },
    leave (element) {
      element.style.height = getComputedStyle(element).height
      // eslint-disable-next-line
      getComputedStyle(element).height

      setTimeout(() => {
        element.style.height = 0
      })
    }
  }
}
</script>

<style lang="scss">
.expand-enter-active,
.expand-leave-active {
  transition: height .3s;
  overflow: hidden;
}
.expand-enter,
.expand-leave-to {
  height: 0;
}

.drawer > * {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.drawer {
  .drawer-head {
    cursor: pointer;
  }
}
</style>
